const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
import { getViewportWidth, headerBreakpoint } from './mobile';

export const ui = {
    init: () => {
        ui.smoothScrollAnchors();
        // window.addEventListener('resize', () => {
        //     ui.smoothScrollAnchors();
        // });
        ui.toggleSitesNav();
        ui.toggleEspaceAssures();
    },

    smoothScrollAnchors: () => {
        document.querySelectorAll('a[href^="#"]').forEach(trigger => {
            trigger.onclick = function (e) {
                e.preventDefault();
                let hash = this.getAttribute('href');
                // console.log(hash);
                if (hash === "#") return false;
                let target = document.querySelector(hash);
                let headerOffset = 0;
                let elementPosition = target.offsetTop;
                let offsetPosition = elementPosition - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            };
        });

    },

    toggleSitesNav: () => {
        let $btnToggleSites = document.querySelector('.btn--toggle-sites');
        if (!$btnToggleSites)
            return;

        let $btnToggleEspaceAssures = document.querySelector('.btn--toggle-espace-assures');
        let $navSites = document.querySelector('.header--sites');
        let $navSitesWrap = $navSites.querySelector('.header--sites-wrap');
        let $navEspaceAssures = document.querySelector('.header--espace-assures');
        $btnToggleSites.addEventListener('click', () => {
            $btnToggleSites.classList.toggle('active');
            $navSites.classList.toggle('visible');
            let isMobile = getViewportWidth() < headerBreakpoint();
            if (isMobile) {
                $navSites.classList.contains('visible') ? disableBodyScroll($navSitesWrap) : enableBodyScroll($navSitesWrap);
            }
            if ($navEspaceAssures && $navEspaceAssures.classList.contains('visible')) {
                $navEspaceAssures.classList.remove('visible');
            }
            if ($btnToggleEspaceAssures) {
                $btnToggleEspaceAssures.classList.remove('active');
            }
        });
    },

    toggleEspaceAssures: () => {
        let $btnToggleEspaceAssures = document.querySelector('.btn--toggle-espace-assures');
        if (!$btnToggleEspaceAssures)
            return;

        let $btnToggleSites = document.querySelector('.btn--toggle-sites');
        let $navSites = document.querySelector('.header--sites');
        let $navEspaceAssures = document.querySelector('.header--espace-assures');
        $btnToggleEspaceAssures.addEventListener('click', () => {
            $btnToggleEspaceAssures.classList.toggle('active');
            $navEspaceAssures.classList.toggle('visible');
            if ($navSites && $navSites.classList.contains('visible')) {
                $navSites.classList.remove('visible');
            }
            if ($btnToggleSites) {
                $btnToggleSites.classList.remove('active');
            }
        });
    }

}