import { getViewportWidth, headerBreakpoint } from './mobile';

export const a11y = {

    init: () => {

        let $navMain = document.querySelector('.nav--main');
        if (!$navMain) return;

        a11y.setContentAnchor();

        let isMobile = getViewportWidth() < headerBreakpoint();
        if (!isMobile) {
            a11y.setMainNav();
        }

        window.addEventListener('resize', () => {
            isMobile = getViewportWidth() < headerBreakpoint();
            if (!isMobile) {
                a11y.setMainNav();
            }
        });
    },

    setMainNav: () => {

        let $navMain = document.querySelector('.nav--main');
        let $navItems = $navMain.querySelectorAll(' .menu-wrap > ul > li');
        let $parentItems = Array.from($navItems).filter(node => node.classList.contains('has-submenu', 'has-megamenu'));

        Array.prototype.forEach.call($navItems, ($navItem, i) => {

            let $link = Array.from($navItem.children).filter(node => node.matches('a'));

            if ($link.length > 0) {

                $link[0].addEventListener('focusin', (e) => {

                    // force focusout on other items
                    Array.prototype.forEach.call($parentItems, ($parentItem, i) => {
                        $parentItem.classList.remove('focus');
                    });

                    if (e.target.classList.contains('submenu-parent', 'megamenu-parent')) {
                        $navItem.classList.add('focus');
                    }

                });

                $link[0].addEventListener('focusout', () => {
                    setTimeout(function () {
                        if (!$navItem.contains(document.activeElement)) {
                            $navItem.classList.remove('focus');
                        }
                    }, 100);
                });

            }

            // Special behaviour for Search
            if ($navItem.classList.contains('search--main')) {
                let $btnSearch = $navItem.querySelector('.btn--toggle-search');
                // let $search =header--formsearch-wrap
                $btnSearch.addEventListener('focusin', (e) => {
                    // force focusout on other items
                    Array.prototype.forEach.call($parentItems, ($parentItem, i) => {
                        $parentItem.classList.remove('focus');
                    });
                });
            }

        });

    },

    setContentAnchor: () => {
        // set #content to first Section (skip Headers);
        let $sections = document.querySelector('.page-content');
        let $firstContent = Array.from($sections.children).filter(node => !node.matches('header') && !node.classList.contains('breadcrumbs'));
        $firstContent[0].id = 'content';

    }
}